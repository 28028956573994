<template>
    <pendo-radio-group
        class="pendo-layout-type-chooser"
        direction="horizontal"
        :disabled="disabled"
        :value="layoutType"
        @change="handleCardClick">
        <div
            v-for="option in layoutOptions"
            :key="option.value"
            class="pendo-layout-type-chooser__card"
            @keydown.enter="handleCardClick(option.value)"
            @click="handleCardClick(option.value)">
            <pendo-action-card
                shadow="hover"
                :class="{
                    active: option.value === layoutType && !disabled
                }"
                :disabled="disabled">
                <template #media>
                    <img
                        alt=""
                        :src="option.icon">
                </template>
                <template #description>
                    <div class="pendo-layout-type-chooser__card-content">
                        <pendo-radio
                            :name="option.value"
                            :value="option.value" />
                        <div class="pendo-layout-type-chooser__card-description">
                            <div class="pendo-layout-type-chooser__card-title">
                                {{ option.title }}
                            </div>
                            <div class="pendo-layout-type-chooser__card-subtitle">
                                {{ option.subtitle }}
                            </div>
                        </div>
                    </div>
                </template>
            </pendo-action-card>
        </div>
    </pendo-radio-group>
</template>

<script>
import PendoRadioGroup from '@/components/radio-group/pendo-radio-group';
import PendoRadio from '@/components/radio/pendo-radio';
import PendoActionCard from '@/components/action-card/pendo-action-card';

export default {
    name: 'PendoLayoutTypeChooser',
    components: {
        PendoRadio,
        PendoRadioGroup,
        PendoActionCard
    },
    props: {
        layoutType: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['select-layout-type'],
    data () {
        return {
            layoutOptions: [
                {
                    value: 'overlay',
                    title: 'Overlay',
                    subtitle:
                        'Guide appears above the page’s content and can be activated by a badge, event, or automatically on page load. Think classic modals, tool tips, polls, or multi-step tours.',
                    icon: 'https://cdn.pendo.io/img/guides/guides-overlay-layout.svg'
                },
                {
                    value: 'embedded',
                    title: 'Embedded',
                    subtitle:
                        'Guide appears in-line with the page’s content, contained in an existing element and activated automatically on page load. Great for contextual info, upsells, or dynamic content.',
                    icon: 'https://cdn.pendo.io/img/guides/guides-embedded-layout.svg'
                }
            ]
        };
    },
    methods: {
        handleCardClick (type) {
            if (!this.disabled) {
                this.$emit('select-layout-type', type);
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-layout-type-chooser) {
    @include element(card) {
        cursor: pointer;
    }

    @include element(card-content) {
        display: flex;
    }

    @include element(card-title) {
        font-size: 16px;
        font-weight: 600;
    }

    @include element(card-subtitle) {
        font-size: $font-size-mini;
        color: $color-gray-70;
    }

    @include block(pendo-radio-group) {
        &.is-horizontal {
            gap: 32px;
        }
    }

    @include block(pendo-action-card) {
        width: 437px;

        &.active {
            border-color: $color-teal-70;
        }

        &.is-vertical {
            @include element(media) {
                background-color: $color-gray-10;
                border-bottom: 1px solid $color-gray-40;
                max-width: inherit;
                border-radius: 4px 4px 0 0;
            }
            @include element(description) {
                margin-top: 0;
            }
        }
    }
}
</style>
