<template>
    <div
        class="pendo-multiselect-option--actions"
        :class="{
            'pendo-multiselect-option--prefix-icon': option.icon,
            'pendo-multiselect-option--aside': option.aside,
            'pendo-multiselect-option--suffix-actions': actions
        }"
        v-bind="$attrs"
        v-on="listeners">
        <pendo-icon
            v-if="option.icon"
            class="pendo-multiselect-option__icon"
            v-bind="icon" />
        <div class="pendo-multiselect-option__label-container">
            <span
                ref="label"
                v-pendo-tooltip="tooltipConfig"
                class="pendo-multiselect-option__label">
                {{ label }}
            </span>
            <span
                v-if="option.aside"
                class="pendo-multiselect-option__aside">
                {{ option.aside }}
            </span>
        </div>
        <div
            v-if="showActions"
            class="pendo-multiselect-option__actions-container">
            <span
                v-for="action in actions"
                :key="action.type"
                v-pendo-tooltip="{
                    content: action.tooltip,
                    offset: 0,
                    delay: { show: 300, hide: 0 }
                }">
                <pendo-button
                    class="pendo-multiselect-option__action"
                    size="mini"
                    type="tertiary"
                    :disabled="action.disabled"
                    :icon="action.icon && action.icon.type"
                    @click.stop="$emit(action.type, option)" />
            </span>
        </div>
    </div>
</template>

<script>
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoIcon from '@/components/icon/pendo-icon';
import PendoButton from '@/components/button/pendo-button';

export default {
    name: 'PendoActionsOption',
    components: {
        PendoButton,
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    inject: ['multiselect'],
    props: {
        option: {
            type: [Object, String],
            required: true
        },
        index: {
            type: Number,
            default: -1
        },
        actions: {
            type: [Array, Boolean],
            default: () => [
                {
                    type: 'edit',
                    tooltip: 'Edit',
                    icon: {
                        type: 'edit'
                    }
                },
                {
                    type: 'copy',
                    tooltip: 'Copy',
                    icon: {
                        type: 'copy'
                    }
                },
                {
                    type: 'delete',
                    tooltip: 'Delete',
                    icon: {
                        type: 'trash-2'
                    }
                }
            ]
        },
        showActionsOnDisabledGroups: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            useInternalHoverListener: false,
            forceShowActions: false,
            tooltipTimeout: null
        };
    },
    computed: {
        tooltipConfig () {
            const isOptionSelected = this.multiselect.isOptionSelected(this.option);
            if (isOptionSelected) {
                return false;
            }

            const isHovered = this.multiselect.showPointer && this.multiselect.pointer === this.index;

            if (this.option && this.option.tooltip && isHovered) {
                return {
                    multiline: true,
                    arrow: true,
                    html: false,
                    ...this.option.tooltip,
                    trigger: 'manual' // force `manual`, allow override of all other config values
                };
            }

            return false;
        },
        listeners () {
            if (this.useInternalHoverListener) {
                return {
                    ...this.$listeners,
                    mouseenter: this.toggleHoverStatus,
                    mouseleave: this.toggleHoverStatus
                };
            }

            return this.$listeners;
        },
        label () {
            return this.multiselect.getOptionLabel(this.option);
        },
        icon () {
            return {
                size: 14,
                ...this.option.icon
            };
        },
        showActions () {
            if (this.option.disabled && !this.showActionsOnDisabledGroups) {
                return false;
            }

            if (this.useInternalHoverListener) {
                return this.actions && this.forceShowActions;
            }

            return this.actions && this.multiselect.showPointer && this.multiselect.pointer === this.index;
        }
    },
    watch: {
        // since mouse events are triggered in a parent component, we need to manually show/hide tooltip
        // unfortunately `manual` trigger type in v-tooltip doesn't support delays
        tooltipConfig (config) {
            clearTimeout(this.tooltipTimeout);

            if (config) {
                this.tooltipTimeout = setTimeout(() => {
                    if (this.tooltipConfig) {
                        this.$refs.label._tooltip.show();
                    }
                }, 300);

                return;
            }

            this.$refs.label._tooltip.hide();
        }
    },
    mounted () {
        if (this.$el && this.$el.parentNode) {
            this.$el.parentNode.style.paddingRight = '8px';
            // if using inside of header or beforeList slot, handle hover internally
            this.useInternalHoverListener =
                !!this.$el.closest('.pendo-multiselect__header') ||
                !!this.$el.closest('.pendo-multiselect__before-list') ||
                this.showActionsOnDisabledGroups;
        }
    },
    methods: {
        toggleHoverStatus () {
            if (!this.useInternalHoverListener) {
                return;
            }

            this.multiselect.pointerSet(-1);
            this.forceShowActions = !this.forceShowActions;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect-option) {
    @include modifier(actions) {
        @include font-base;
        line-height: 26px;
        user-select: none;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        position: absolute;
        right: 8px;
        left: 16px;

        @include element(icon) {
            margin-right: 8px;
        }

        @include element(label-container) {
            @include ellipsis;
            flex: 1;
        }

        @include element(label) {
            margin-right: auto;
        }

        @include element(aside) {
            @include ellipsis;
            color: $color-gray-70;
            font-weight: 300;
        }

        @include element(actions-container) {
            display: flex;
            margin-left: 8px;
            justify-self: end;
        }

        @include element(action) {
            color: $color-gray-70;
            background-color: transparent !important;
            padding: 4px !important;
            min-width: 16px !important;

            &:hover {
                color: $color-gray-100;
                background-color: transparent !important;
            }

            .pendo-icon__content {
                stroke-width: 2;
            }
        }
    }
}
</style>
