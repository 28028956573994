<template>
    <div class="guide-alerts">
        <pendo-table
            ref="pendoTable"
            class="guide-alerts--table"
            title="Alerts"
            row-key="childKey"
            manage-columns
            resizable
            auto-height
            :data="processedData"
            :filters="tableFilters"
            :columns="columns"
            :group-config="{
                enabled: isGroupedByGuide,
                groupKey: 'guideName',
                sortChildren: true
            }"
            :scroll-config="{ pageMode: true, rowHeight: null }"
            :row-class-name="isMuted"
            :status="guideAlertsAggStatus"
            @column-resize="resizeColumn"
            @column-change="changeColumns"
            @link-click="onLinkClick"
            @table-data-change="tableDataChange">
            <template #headerActions>
                <div class="guide-alerts-table--header-actions">
                    <div data-cy="guide-alerts-show-muted">
                        <pendo-checkbox
                            v-model="showMutedAlerts"
                            :label="mutedAlertText" />
                    </div>
                    <pendo-checkbox
                        v-model="isGroupedByGuide"
                        label="Group By Guide"
                        class="group-alerts-by-guide" />
                    <pendo-divider
                        direction="vertical"
                        width="1px"
                        height="24px"
                        stroke="#DADCE5" />
                    <pendo-button
                        v-if="hasAdminAccess"
                        data-cy="alert-settings"
                        theme="app"
                        type="link"
                        label="Alert Settings"
                        prefix-icon="sliders"
                        @click="$emit('show-alert-settings')" />
                </div>
            </template>
            <template #status>
                <pendo-status-overlay
                    :state="guideAlertsAggStatus"
                    :options="{
                        emptyText,
                        emptySubtext,
                        loadingText
                    }" />
            </template>
            <template #app="{ row }">
                <pendo-app-display
                    v-if="productType === 'adopt'"
                    :apps="row.app" />
                <pendo-app-cell
                    v-else
                    :row="row" />
            </template>
            <template #groupRow="{ row }">
                <div class="group-header__guide-name">
                    {{ row.guideName }} ({{ row.childRowCount }})
                </div>
            </template>
            <template #alertStatus="{ row }">
                <div data-cy="adjust-alert-status">
                    <guide-alerts-status
                        v-if="row"
                        :ref="`${row.childKey}:alertStatus`"
                        :row="row"
                        @update-status="handleAlertStatusUpdate" />
                </div>
            </template>
            <template #segment="{ row }">
                <a
                    v-if="row.segment.id && row.segment.name !== 'Everyone' && productType === 'engage'"
                    :href="`/segments?segment=${row.segment.id}`"
                    target="_blank">
                    {{ row.segment.name }}
                </a>
                <pendo-button
                    v-else-if="row.segment.name === 'Custom Segment'"
                    theme="app"
                    type="link"
                    :label="row.segment.name"
                    @click="onLinkClick" />
                <div v-else>
                    {{ row.segment.label || row.segment.name }}
                </div>
            </template>
            <template #visitorsImpacted="{ row }">
                <div data-cy="show-impacted-users-link">
                    <pendo-button
                        theme="app"
                        type="link"
                        @click="showUsers(row)">
                        {{ row.visitorsImpacted }}
                    </pendo-button>
                </div>
            </template>
            <template #actions="{ row }">
                <div class="guide-alerts-table--actions">
                    <pendo-actions-cell
                        :row="row"
                        :actions="[
                            {
                                type: row.muted ? 'unmute' : 'mute',
                                icon: row.muted ? 'bell-off' : 'bell',
                                tooltip: mutedActionTooltip(row),
                                disabled: !row.canChangeAlert || isUpdating
                            },
                            {
                                type: 'show-notes',
                                icon: 'message-circle',
                                tooltip: 'Show Notes'
                            }
                        ]"
                        @show-notes="showNotes"
                        @mute="onShowActiveAlertMuteModal"
                        @unmute="unmute" />
                    {{ getNoteCount(row) }}
                </div>
            </template>
        </pendo-table>
        <pendo-modal
            type="confirmation"
            :confirm-button-config="confirmButtonConfig"
            :title="activeAlertModal.title"
            :visible="activeAlertModal.isVisible"
            :message="activeAlertModal.modalMessage"
            @cancel="cancelModal"
            @confirm="confirmModal" />
    </div>
</template>

<script>
import PendoDivider from '@/components/divider/pendo-divider.vue';
import PendoTable from '@/components/table/pendo-table.vue';
import PendoAppCell from '@/components/table/cell-types/pendo-app-cell.vue';
import PendoAppDisplay from '@/composites/app-display/pendo-app-display.vue';
import PendoCheckbox from '@/components/checkbox/pendo-checkbox.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoModal from '@/components/modal/pendo-modal.vue';
import PendoNotification from '@/components/notification/pendo-notification.js';
import PendoActionsCell from '@/components/table/cell-types/pendo-actions-cell.vue';
import PendoStatusOverlay from '@/composites/status-overlay/pendo-status-overlay.vue';
import GuideAlertsStatus from './guide-alerts-status';
import moment from 'moment';
import get from 'lodash/get';
import clone from 'lodash/clone';
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import capitalize from 'lodash/capitalize';

export default {
    name: 'GuideAlertsTable',
    components: {
        PendoDivider,
        PendoTable,
        PendoAppCell,
        PendoAppDisplay,
        PendoCheckbox,
        PendoButton,
        PendoModal,
        PendoActionsCell,
        PendoStatusOverlay,
        GuideAlertsStatus
    },
    props: {
        errorData: {
            type: Array,
            required: true
        },
        guideAlertsAggStatus: {
            type: String,
            required: true
        },
        segmentsMap: {
            type: Object,
            required: true
        },
        filters: {
            type: Array,
            default: () => []
        },
        hasAdminAccess: {
            type: Boolean,
            default: false
        },
        tableConfig: {
            type: Object,
            default: () => {
                return {
                    guideErrorsColumns: {},
                    guideErrorsWidths: {},
                    tableToggles: {}
                };
            }
        },
        productType: {
            type: String,
            default: 'engage'
        }
    },
    data () {
        return {
            isGroupedByGuide: true,
            showMutedAlerts: false,
            activeAlertModal: {
                isVisible: false,
                alert: null,
                modalMessage: '',
                type: null,
                loading: false,
                title: ''
            },
            initialColumns: [
                'impactedStep.name',
                'errorType',
                'alertStatus',
                'visitorsImpacted',
                'percentVisitorsImpacted',
                'firstOccurrence',
                'lastOccurrence',
                'actions'
            ],
            columns: [],
            columnWidthsConfig: undefined,
            mutedFilter: [],
            isUpdating: false,
            loadingText: 'Loading...',
            emptyText: 'There are no alerts found matching the current filters.',
            emptySubtext:
                'Consider adjusting any applied filters. Any alerts on your guides will be seen here with information on impact and how it could potentially be fixed.'
        };
    },
    computed: {
        mutedAlerts () {
            return this.errorData.filter((alert) => alert.muted).length;
        },
        mutedAlertText () {
            return `Show Muted Alerts (${this.mutedAlerts})`;
        },
        confirmButtonConfig () {
            return {
                loading: this.activeAlertModal.loading
            };
        },
        defaultColumns () {
            const columnHeaders = clone(this.initialColumns);
            if (!this.isGroupedByGuide) {
                columnHeaders.splice(1, 0, 'guideName');
            }

            return columnHeaders;
        },
        availableColumns () {
            const options = [
                {
                    prop: 'impactedStep.name',
                    type: 'link',
                    label: 'Impacted Step',
                    formatter: (row) => {
                        const name = get(row.steps[row.impactedStepIndex], 'name', false);
                        if (name) {
                            return `${name}`;
                        }

                        return `Step ${row.impactedStepIndex + 1}`;
                    },
                    sortable: true
                },
                {
                    label: 'Alert Status',
                    prop: 'alertStatus',
                    width: 150,
                    sortable: true
                },
                {
                    prop: 'visitorsImpacted',
                    label: 'Visitors Impacted',
                    sortable: true
                },
                {
                    prop: 'totalVisitors',
                    label: 'Total Visitors',
                    sortable: true
                },
                {
                    prop: 'percentVisitorsImpacted',
                    label: 'Alert Rate',
                    sortable: true,
                    formatter: (row) => `${row.percentVisitorsImpacted}%`
                },
                {
                    prop: 'errorType',
                    label: 'Alert Type',
                    sortable: true,
                    minWidth: 200
                },
                {
                    prop: 'lastUpdatedByUser',
                    label: 'Last Updated By',
                    sortable: true
                },
                {
                    prop: 'lastUpdatedAt',
                    label: 'Last Updated Time',
                    formatter: (row) => moment(row.lastUpdatedAt).format('MMM D, YYYY, h:mm:ss a'),
                    width: 200,
                    sortable: true
                },
                {
                    prop: 'launchMethodText',
                    label: 'Activation Method',
                    sortable: true
                },
                {
                    prop: 'appId',
                    slot: 'app',
                    label: 'App',
                    minWidth: 160,
                    sortable: true,
                    sortBy: (row) => row.app.displayName.toLowerCase()
                },
                {
                    prop: 'segment',
                    label: 'Segment',
                    minWidth: 200,
                    sortable: true,
                    sortBy: (row) => row.segment.name.toLowerCase()
                },
                {
                    prop: 'firstOccurrence',
                    label: 'First Alert Date',
                    minWidth: 200,
                    sortable: true,
                    formatter: (row) => moment(row.firstOccurrence).format('MMM D, YYYY, h:mm:ss a')
                },
                {
                    prop: 'lastOccurrence',
                    label: 'Last Alert Date',
                    minWidth: 200,
                    sortable: true,
                    formatter: (row) => moment(row.lastOccurrence).format('MMM D, YYYY, h:mm:ss a')
                },
                {
                    prop: 'actions',
                    type: 'actions',
                    width: 80
                }
            ];
            if (!this.isGroupedByGuide) {
                options.push({
                    prop: 'guideName',
                    label: 'Guide Name',
                    sortable: true
                });
            }

            return options;
        },
        tableToggleState () {
            return `${this.showMutedAlerts}|${this.isGroupedByGuide}`;
        },
        processedData () {
            return this.errorData.reduce((acc, guide) => {
                const { impactedStepId, displayAlertType, steps } = guide;
                const stepIndex = this.findStepIndex(steps, impactedStepId);
                if (stepIndex > -1) {
                    const segment = this.getSegmentInfo(guide);
                    const errorType = this.getErrorType(displayAlertType);
                    const impactedStepIndex = stepIndex;

                    acc.push({
                        ...guide,
                        segment,
                        errorType,
                        impactedStepIndex
                    });
                }

                return acc;
            }, []);
        },
        tableFilters () {
            return [...this.filters, ...this.mutedFilter];
        }
    },
    watch: {
        tableToggleState: debounce(async function (toggleState) {
            const [showMutedAlerts, isGroupedByGuide] = toggleState.split('|');
            const values = {
                showMutedAlerts: showMutedAlerts === 'true',
                isGroupedByGuide: isGroupedByGuide === 'true'
            };
            this.updateUserSettings('displayAlertTableToggles', values);
        }, 500),
        defaultColumns () {
            this.processColumns(this.columnWidthsConfig);
        },
        showMutedAlerts: {
            immediate: true,
            handler () {
                this.mutedFilter = [{ filterFn: (row) => this.showMutedAlerts || !row.muted }];
            }
        }
    },
    created () {
        this.getTableColumns();
        this.getTableToggles();
    },
    methods: {
        getTableColumns () {
            const { guideErrorsColumns, guideErrorsWidths } = this.tableConfig;

            if (get(guideErrorsWidths, 'value', false)) {
                this.columnWidthsConfig = guideErrorsWidths.value;
            }

            if (get(guideErrorsColumns, 'value', false)) {
                const columnConfig = guideErrorsColumns.value;
                if (columnConfig.indexOf('actions') < 0) {
                    columnConfig.push('actions');
                }

                // We want to make sure the first column is correct
                // since you can not re-order it
                const expectedFirstColumn = this.availableColumns[0].prop;
                if (columnConfig[0] !== expectedFirstColumn) {
                    const indexOfFirstColumn = columnConfig.indexOf(expectedFirstColumn);
                    if (indexOfFirstColumn < 0) {
                        columnConfig.unshift(expectedFirstColumn);
                    } else if (indexOfFirstColumn > 0) {
                        columnConfig[indexOfFirstColumn] = columnConfig[0];
                        columnConfig[0] = expectedFirstColumn;
                    }
                }

                this.initialColumns = columnConfig;
            } else {
                this.processColumns(this.columnWidthsConfig);
            }
        },
        getTableToggles () {
            const toggles = get(this.tableConfig, 'tableToggles.value', {});

            this.showMutedAlerts = get(toggles, 'showMutedAlerts', false);
            this.isGroupedByGuide = get(toggles, 'isGroupedByGuide', true);
        },
        onLinkClick ({ row }) {
            this.$emit('step-link-click', row.guideId);
        },
        confirmModal () {
            this.activeAlertModal.loading = true;
            const { alert, type } = this.activeAlertModal;
            if (type === 'resolve') {
                alert.alertStatus = 'Resolved';
            }
            if (type === 'mute') {
                alert.muted = true;
            }

            this.updateAlertRow({ activeAlert: alert, isVisible: true });
        },
        isMuted ({ row }) {
            if (row.muted) {
                return 'muted';
            }
        },
        cancelModal () {
            if (this.activeAlertModal.type === 'resolve') {
                this.$refs[`${this.activeAlertModal.alert.childKey}:alertStatus`].setStatus();
            }
            this.resetActiveAlertModal();
        },
        showUsers (row) {
            const {
                visitorsImpacted: users,
                guideId,
                impactedStepId: guideStepId,
                displayAlertType: guideShownReason
            } = row;
            this.$emit('show-panel-users', { users, guideId, guideStepId, guideShownReason });
        },
        showNotes (alert) {
            this.$emit('show-panel-notes', alert);
        },
        processColumns (columnWidthsConfig) {
            this.columns = compact(
                this.availableColumns.reduce((columns, column) => {
                    const index = this.defaultColumns.indexOf(column.prop);
                    if (index >= 0) {
                        column.visible = true;
                        columns[index] = column;
                    } else {
                        column.visible = undefined;
                        columns.push(column);
                    }

                    if (columnWidthsConfig) {
                        const savedColumnWidth = get(columnWidthsConfig, `['${column.prop}']`);
                        if (savedColumnWidth) {
                            column.width = savedColumnWidth;
                        }
                    }

                    return columns;
                }, new Array(this.defaultColumns.length))
            );
        },
        changeColumns (columns) {
            this.initialColumns = columns.columns;
            this.updateUserSettings('guideErrorsColumns', this.initialColumns);
        },
        resizeColumn (column) {
            const columnWidthsConfig = this.columns.reduce((config, localColumn) => {
                const newWidth = column.column === localColumn.prop ? column.width : localColumn.width;
                config[localColumn.prop] = newWidth;
                localColumn.width = newWidth;

                return config;
            }, {});

            this.columnWidthsConfig = columnWidthsConfig;
            this.updateUserSettings('guideErrorsWidths', columnWidthsConfig);
        },
        muteModalMessage (row) {
            let stepname = get(row.steps[row.impactedStepIndex], 'name', false);
            if (!stepname) {
                stepname = `Step ${row.impactedStepIndex + 1}`;
            }

            return `"${row.guideName}'s step: ${stepname}, type: ${row.errorType}" will remain muted until it is unmuted.`;
        },
        onShowActiveAlertMuteModal (alert) {
            this.activeAlertModal = {
                loading: false,
                title: 'Mute this Alert?',
                alert,
                modalMessage: this.muteModalMessage(alert),
                isVisible: true,
                type: 'mute'
            };
        },
        unmute (alert) {
            alert.muted = false;
            this.updateAlertRow({ activeAlert: alert });
        },
        updateAlertRow ({ activeAlert, isVisible = false }) {
            this.isUpdating = true;
            const { alertStatus, muted, appId, guideId, impactedStepId, displayAlertType } = activeAlert;
            const alert = {
                status: alertStatus,
                muted,
                appId,
                guideId,
                guideStepId: impactedStepId,
                guideDisplayAlertType: displayAlertType
            };

            if (!activeAlert.hasAlertCreated) {
                return this.$emit('create-display-alert', { alert, activeAlert, isVisible });
            }

            this.$emit('update-display-alert', { alert, activeAlert, isVisible });
        },
        /**
         * @public
         */
        informError (closeModal = false) {
            this.isUpdating = false;
            if (closeModal) {
                this.resetActiveAlertModal();
            }

            return PendoNotification({
                type: 'error',
                title: 'Alert Not Updated',
                message: 'Looks like something went wrong. Please refresh and try again.',
                duration: 3000
            });
        },
        handleAlertStatusUpdate (alert, status) {
            if (status === 'Resolved') {
                this.activeAlertModal = {
                    alert,
                    isVisible: true,
                    title: 'Resolve this Alert?',
                    modalMessage: 'This guide step will remain resolved until another alert is triggered.',
                    type: 'resolve',
                    loading: false
                };

                return;
            }

            alert.alertStatus = status;
            this.updateAlertRow({ activeAlert: alert });
        },
        getNoteCount (row) {
            const numberOfNotes = get(row, 'notes', []).length;

            return numberOfNotes || '';
        },
        resetActiveAlertModal () {
            this.activeAlertModal = {
                alert: null,
                type: null,
                title: '',
                modalMessage: '',
                isVisible: false,
                loading: false
            };
        },
        /**
         * @public
         */
        updateTableRow (activeAlert, closeModal = false) {
            this.$refs.pendoTable.updateRow(activeAlert);
            this.isUpdating = false;
            if (closeModal) {
                this.resetActiveAlertModal();
            }
        },
        updateUserSettings (name, value) {
            const settings = {
                name,
                namespaced: true,
                value,
                force: true
            };
            this.$emit('update-user-settings', settings);
        },
        tableDataChange (data) {
            this.$emit('table-data-change', data);
        },
        getErrorType (error) {
            const reason = error;

            switch (reason) {
                case 'page':
                    return 'Page Mismatch';
                case 'element':
                    return 'Element Missing';
                case 'download-image-error':
                case 'imagedownloaderror':
                case 'image-not-ready':
                    return 'Download Image Error';
                case 'download-content-error':
                case 'contentdownloaderror':
                    return 'Download Content Error';
                case 'content-not-ready':
                case 'contentnotready':
                    return 'Content Not Ready';
                case 'configurationerror':
                default:
                    return 'Other';
            }
        },
        getSegmentInfo (guide) {
            if (!guide.audienceFilter || guide.audienceFilter.length === 0) {
                return { name: 'Everyone' };
            }

            const isCustomNestedSegment = this.filtersHaveSegmentRule(guide.audienceFilter);
            const segmentId = get(guide.audienceFilter[0], 'segmentId');

            if (!isCustomNestedSegment && segmentId) {
                const segment = this.segmentsMap[segmentId];
                if (get(segment, 'name', '') === 'Everyone' && get(segment, 'aside', false)) {
                    segment.label = `${segment.name} ${segment.aside}`;
                }

                return segment;
            }

            return {
                name: 'Custom Segment'
            };
        },
        findStepIndex (steps, stepId) {
            if (!steps) {
                return -1;
            }

            if (Array.isArray(steps)) {
                return steps.findIndex((step) => step.id === stepId);
            }

            return steps.id === stepId ? 0 : -1;
        },
        filtersHaveSegmentRule (filters = []) {
            return filters.some((filter) =>
                (filter.or ? filter.or.some((rule) => rule.kind === 'segment') : filter.kind === 'segment')
            );
        },
        mutedActionTooltip (row) {
            const action = row.muted ? 'unmute' : 'mute';
            if (!row.canChangeAlert) {
                return `You do not have sufficient permissions for ${row.app.displayName} to ${action} the alert`;
            }

            return capitalize(action);
        }
    }
};
</script>
<style lang="scss" scoped>
.guide-alerts {
    margin-top: 16px;
}

.guide-alerts--table {
    ::v-deep .pendo-table__row.muted {
        .pendo-table__link {
            color: $color-gray-70;
            text-decoration: line-through;
        }
    }

    .guide-alerts-table--header-actions {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 16px;
        margin-right: 8px;
        align-items: center;
    }

    .group-header__guide-name {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.1em;
        color: $color-gray-70;
    }

    .guide-alerts-table--actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        word-break: normal;
    }
}
</style>
