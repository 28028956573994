<template>
    <div
        class="pendo-action-card"
        :class="{
            'is-disabled': disabled,
            [`is-${orientation}`]: orientation,
            [`is-${shadowType}-shadow`]: shadowType
        }">
        <div
            v-if="$slots.media"
            class="pendo-action-card__media">
            <!-- @slot pass any media type here such as a png, svg, or jpg -->
            <slot name="media" />
        </div>
        <div
            v-if="$slots.icon || icon"
            class="pendo-action-card__icon">
            <!-- @slot icon slot alternative to passing icon as a prop, pass any media type here such as a png, svg, or jpg -->
            <slot name="icon">
                <pendo-icon
                    :type="icon"
                    :size="iconSize" />
            </slot>
        </div>
        <div class="pendo-action-card__body">
            <div class="pendo-action-card__title">
                <!-- @slot title slot alternative to passing title as prop -->
                <slot name="title">
                    {{ title }}
                </slot>
            </div>
            <div
                v-if="subtitle || $slots.subtitle"
                class="pendo-action-card__subtitle">
                <!-- @slot subtitle alternative to passing subtitle as prop -->
                <slot name="subtitle">
                    {{ subtitle }}
                </slot>
            </div>
            <div
                v-if="description || $slots.description"
                class="pendo-action-card__description">
                <!-- @slot description alternative to passing description as prop -->
                <slot name="description">
                    {{ description }}
                </slot>
            </div>
            <div
                v-if="$slots.actions"
                class="pendo-action-card__actions">
                <!-- @slot used to add actions onto the card -->
                <slot name="actions" />
            </div>
        </div>
    </div>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon.vue';

export default {
    name: 'PendoActionCard',
    components: {
        PendoIcon
    },
    props: {
        /**
         * Card orientation
         * @values vertical, horizontal
         */
        orientation: {
            type: String,
            default: 'vertical',
            validator: (orientation) => ['vertical', 'horizontal'].includes(orientation)
        },
        /**
         * used to display an icon at the top of the card
         */
        icon: {
            type: String,
            default: ''
        },
        /**
         * sets the size of the icon
         */
        iconSize: {
            type: [String, Number],
            default: '32'
        },
        /**
         * Title text of card
         */
        title: {
            type: String,
            default: ''
        },
        /**
         * Secondary text directly below title
         */
        subtitle: {
            type: String,
            default: ''
        },
        /**
         * Description text
         */
        description: {
            type: String,
            default: ''
        },
        /**
         * Disables card interactions
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * When to show card shadows
         * @values always, hover, never
         */
        shadow: {
            type: String,
            default: 'never',
            validator: (shadow) => ['always', 'hover', 'never'].includes(shadow)
        }
    },
    computed: {
        shadowType () {
            return this.disabled ? 'never' : this.shadow;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-action-card) {
    @include font-base;
    display: flex;
    border-radius: $card-border-radius;
    border: $card-border;
    background-color: $color-white;

    @include is(hover-shadow) {
        transition: $card-hover-transition;

        &:hover {
            box-shadow: $card-hover-shadow;
            transform: $card-hover-transform;
        }
    }

    @include is(always-shadow) {
        box-shadow: $card-hover-shadow;
    }

    @include is(disabled) {
        cursor: not-allowed;
        background-color: $color-gray-10;
    }

    @include element(media) {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    @include element(icon) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 16px 16px 0 16px;
    }

    @include is(vertical) {
        flex-direction: column;
        width: 340px;

        @include element(media) {
            max-height: 160px;
            max-width: 340px;
        }
    }

    @include is(horizontal) {
        flex-direction: row;
        width: 600px;

        @include element(media) {
            max-height: 170px;
            max-width: 185px;
        }

        @include element(icon) {
            align-items: flex-start;
            flex-direction: column;
            padding: 16px 0 16px 16px;
        }
    }

    @include element(body) {
        display: flex;
        flex-direction: column;
        padding: 16px;
    }

    @include element(title) {
        @include ellipsis;
        font-weight: 600;
        font-size: $card-media-title-size;
        line-height: $card-media-title-line-height;
    }

    @include element(subtitle) {
        @include ellipsis;
        font-size: $card-media-subtitle-size;
        color: $card-media-subtitle-color;
    }

    @include element(description) {
        margin-top: 16px;
        line-height: 150%;
    }

    @include element(actions) {
        margin-top: 16px;
    }
}
</style>
