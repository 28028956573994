<template>
    <pendo-modal
        class="pendo-resource-column-chooser__modal"
        :title="modalTitle"
        :width="modalWidth"
        height="auto"
        max-height="85%"
        append-to-body
        :visible="visible"
        @open="initLocalColumns"
        @closed="onModalClosed"
        @close="$emit('close')">
        <div class="pendo-column-chooser__description">
            <slot name="description">
                Add, remove, or reorder columns to customize your table.
            </slot>
        </div>
        <pendo-column-reorder
            v-if="renderColumnReorder"
            :value="selectedColumns"
            :available-columns="availableColumns"
            :disabled="isLoadingMetadata"
            value-key="id"
            label-key="name"
            add-button-label="Add column"
            @columns-change="updateColumns">
            <template
                #multiselect="{
                    model,
                    index,
                    handleColumnSelect,
                }">
                <pendo-multiselect
                    :append-to-body="false"
                    :value="model"
                    multiple
                    :placeholder="model[index].name || optionPlaceholder"
                    full-width
                    :max-trigger-width="286"
                    :max-menu-width="286"
                    :options="availableOptions"
                    :show-selected-values="false"
                    value-key="id"
                    label-key="name"
                    sort-by="name"
                    :disabled="model[index].immutable"
                    @select="onColumnSelect($event, index, handleColumnSelect)">
                    <template #option="{ option }">
                        <pendo-icon-option :option="option" />
                    </template>
                    <template #selectedLabel>
                        <pendo-icon-option
                            v-if="model[index].name"
                            :option="model[index]" />
                    </template>
                </pendo-multiselect>
                <template v-if="isProductOption(model[index])">
                    <resource-selector
                        :loading="isLoadingResources"
                        v-bind="resourceSelectorPropsForColumn(model, index)"
                        @select="handleColumnSelect($event, index)" />
                    <template v-if="model[index].type !== 'poll'">
                        <pendo-multiselect
                            :append-to-body="false"
                            class="fact-selector"
                            v-bind="factSelectorPropsForColumn(model, index)"
                            :max-trigger-width="286"
                            :max-menu-width="286"
                            :allow-empty="false"
                            value-key="fact"
                            sort-by="label"
                            full-width
                            @select="
                                handleResourceSelectorColumnSelect(
                                    model,
                                    index,
                                    'fact',
                                    $event.fact,
                                    handleColumnSelect
                                )
                            " />
                    </template>
                    <template v-if="model[index].type === 'poll'">
                        <pendo-multiselect
                            :append-to-body="false"
                            class="poll-selector"
                            v-bind="pollSelectorPropsForColumn(model, index)"
                            placeholder="Select Poll Question"
                            :max-trigger-width="286"
                            :max-menu-width="286"
                            :allow-empty="false"
                            label-key="question"
                            sort-by="question"
                            full-width
                            @select="
                                handleResourceSelectorColumnSelect(
                                    model,
                                    index,
                                    'pollId',
                                    $event.id,
                                    handleColumnSelect
                                )
                            " />
                    </template>
                </template>
            </template>
            <template #add-button>
                <div class="report-column-editor__add-button">
                    <div
                        v-pendo-tooltip="{ content: disableAddColumn ? allSelectedText : null, arrow: true }"
                        class="pendo-column-chooser__add-column"
                        :class="{ 'is-disabled': disableAddColumn }"
                        @click="addColumn"
                        @keydown.space="addColumn">
                        <pendo-icon
                            type="plus"
                            size="16" />
                        {{ addButtonLabel }}
                    </div>
                </div>
            </template>
        </pendo-column-reorder>
        <template #footer>
            <div class="pendo-resource-column-chooser__footer">
                <pendo-button
                    theme="app"
                    type="secondary"
                    size="medium"
                    label="Cancel"
                    @click="$emit('cancel')" />
                <pendo-button
                    :disabled="!isSaveEnabled"
                    theme="app"
                    type="primary"
                    size="medium"
                    label="Save"
                    :loading="saving"
                    @click="onConfirm" />
            </div>
        </template>
    </pendo-modal>
</template>

<script>
import PendoButton from '@/components/button/pendo-button';
import PendoModal from '@/components/modal/pendo-modal';
import PendoColumnReorder from '@/components/table/column-reorder/pendo-column-reorder';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoIconOption from '@/components/multiselect/option-types/pendo-icon-option';
import {
    getResourceOptions,
    getFactOptionsForType,
    getAvailableColumns,
    groupOptionsByKind,
    isProductOption,
    getHasPolls
} from './utils.js';
import uuid from 'uuid';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import ResourceSelector from './resource-selector.vue';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoIcon from '@/components/icon/pendo-icon';

export default {
    name: 'PendoResourceColumnChooser',
    components: {
        ResourceSelector,
        PendoButton,
        PendoModal,
        PendoColumnReorder,
        PendoMultiselect,
        PendoIconOption,
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    props: {
        /**
         * Table columns (see pendo-table)
         */
        columns: {
            type: Array,
            required: true
        },
        /**
         * List of features
         */
        featuresList: {
            type: Array,
            default: () => []
        },
        /**
         * List of guides
         */
        guidesList: {
            type: Array,
            default: () => []
        },
        /**
         * List of pages
         */
        pagesList: {
            type: Array,
            default: () => []
        },
        /**
         * Triggers the modal
         */
        visible: {
            type: Boolean,
            default: false
        },
        /**
         * Modal saving status
         */
        saving: {
            type: Boolean,
            default: false
        },
        /**
         * Loading indicator for aggs that's dependent on the user's selection
         */
        isLoadingResources: {
            type: Boolean,
            default: false
        },
        /**
         * Loading indicator for metadata (available column options)
         */
        isLoadingMetadata: {
            type: Boolean,
            default: false
        },
        /**
         * List of metadata
         */
        metadataList: {
            type: Array,
            default: () => []
        },
        /**
         * Title of the modal
         */
        modalTitle: {
            type: String,
            default: 'Manage Columns'
        },
        /**
         * Text to display when all available columns are selected
         */
        allSelectedText: {
            type: String,
            default: 'All available columns selected.'
        },
        /**
         * Add Column Label
         */
        addButtonLabel: {
            type: String,
            default: 'Add column'
        },
        /**
         * Multiselect control placeholder text
         */
        optionPlaceholder: {
            type: String,
            default: 'Select column'
        },
        /**
         * Set max number of column selections
         */
        maxColumnSelections: {
            type: Number,
            default: undefined
        },
        /**
         * Width in pixels or percents (e.g. 50 or "50px", "50%")
         */
        modalWidth: {
            type: [Number, String],
            default: 400
        }
    },
    data () {
        return {
            localColumns: this.columns,
            renderColumnReorder: false,
            toggleModal: false
        };
    },
    computed: {
        isSaveEnabled () {
            let isMissingValues = false;

            this.localColumns.forEach((column) => {
                const keys = Object.keys(column);
                const id = column.id || '';
                const isSpecialColumn = ['guide', 'poll', 'feature', 'page'].includes(column.type);

                const factPropKey = column.type === 'poll' ? 'pollId' : 'fact';
                const hasFactProp = typeof get(column, factPropKey, undefined) === 'string';
                const hasTypeId = typeof get(column, `${column.type}Id`, undefined) === 'string';
                const hasRequiredFields = id.includes(column.type) && hasTypeId && hasFactProp;

                if (keys.length === 0) {
                    isMissingValues = true;
                }
                if (isSpecialColumn && !hasRequiredFields) {
                    isMissingValues = true;
                }
            });

            return !isMissingValues;
        },
        disableAddColumn () {
            if (this.maxColumnSelections) {
                return this.selectedColumns.length === this.maxColumnSelections;
            }

            return !this.availableOptions.length;
        },
        availableOptions () {
            const available = this.allColumnOptions.filter((option) => {
                return isProductOption(option) || !this.selectedColumns.find((column) => column.id === option.id);
            });

            return groupOptionsByKind(available);
        },
        pages () {
            return getResourceOptions(this.pagesList, 'pageId');
        },
        features () {
            return getResourceOptions(this.featuresList, 'featureId');
        },
        guides () {
            return getResourceOptions(this.guidesList, 'guideId');
        },
        polls () {
            const guidesWithPolls = this.guidesList.filter((guide) => {
                const isResourceCenter = get(guide, 'attributes.resourceCenter') && guide.state !== 'public';

                return !isResourceCenter && getHasPolls(guide);
            });

            return getResourceOptions(guidesWithPolls, 'guideId');
        },
        availableColumns () {
            return groupOptionsByKind(this.allColumnOptions);
        },
        selectedColumns () {
            return this.localColumns
                .filter((column) => column.allowReorder !== false)
                .map((column, index) => {
                    let { id, name } = column;
                    const { prop } = column;
                    if (isProductOption(column)) {
                        const type = column.type === 'poll' ? 'guide' : column.type;
                        const resourceId = (column[`${type}Id`] || '').replace(/-/g, '_') || uuid();
                        const fact = column.type === 'poll' ? column.pollId : column.fact;
                        id = `${column.type}_${resourceId}_${fact}_${index}`;
                    } else if (prop) {
                        id = prop;
                        name = column.label;
                    }

                    return {
                        ...column,
                        id,
                        name,
                        disabled: column.immutable
                    };
                });
        },
        allColumnOptions () {
            return getAvailableColumns(this.metadataList);
        }
    },
    methods: {
        onModalClosed () {
            this.renderColumnReorder = false;
        },
        addColumn () {
            if (this.disableAddColumn) {
                return;
            }
            const columns = [...this.localColumns, {}];
            this.updateColumns(columns);
        },
        initLocalColumns () {
            this.localColumns = this.columns;
            // wait for modal to render before trying to render column chooser
            // to prevent initialization issues with draggable behavior
            setTimeout(() => {
                this.renderColumnReorder = true;
            }, 0);
        },
        updateColumns (value) {
            this.localColumns = value;
        },
        onColumnSelect (column, index, handleColumnSelect) {
            if (isProductOption(column)) {
                this.$emit('fetch-resources', column.type);
            }

            handleColumnSelect(column, index);
        },
        handleResourceSelectorColumnSelect (model, index, propName, prop, handleColumnSelect) {
            const column = { ...model[index], [propName]: prop };
            handleColumnSelect(column, index);
        },
        isProductOption,
        factSelectorPropsForColumn (model, index) {
            const column = model[index];
            const options = getFactOptionsForType(column.type);
            const disabled = !this.resourceSelectorPropsForColumn(model, index).value;

            return {
                disabled,
                value: options.find((option) => option.fact === column.fact),
                options
            };
        },
        pollSelectorPropsForColumn (model, index) {
            const column = model[index];
            const resources = this.polls;
            const selectedGuide = resources.find((guide) => guide.guideId === column.guideId);

            if (!selectedGuide) {
                return {
                    options: [],
                    value: null,
                    disabled: true
                };
            }

            const options = selectedGuide.polls;
            const value = options.find((question) => question.id === column.pollId);

            return {
                disabled: false,
                options,
                value
            };
        },
        resourceSelectorPropsForColumn (model, index) {
            const column = model[index];
            const type = column.type === 'poll' ? 'guide' : column.type;

            const field = `${type}Id`;
            const options = this[`${column.type}s`];
            const placeholder = `Select ${startCase(column.type)}`;
            const value = options.find((option) => option[field] === column[field]);

            return {
                placeholder,
                column,
                field,
                options,
                value
            };
        },
        onConfirm () {
            const formattedColumnsForTable = this.selectedColumns.reduce((acc, column) => {
                if (Object.values(column).every((val) => val === undefined) || column.immutable) {
                    return acc;
                }

                let label = column.name;
                let fact;

                if (isProductOption(column)) {
                    const type = column.type === 'poll' ? 'guide' : column.type;
                    const field = `${type}Id`;
                    if (!column[field]) {
                        return acc;
                    }

                    const resource = this[`${type}s`].find((resource) => resource[field] === column[field]);

                    if (column.fact) {
                        fact = getFactOptionsForType(column.type).find((fact) => fact.fact === column.fact);
                        label = `${fact.label} for ${startCase(`${column.type} ${resource.name}`)}`;
                    } else if (column.pollId) {
                        const selectedPoll = resource.polls.find((question) => question.id === column.pollId);
                        column.poll = selectedPoll;
                        label = `Poll Response for ${startCase(`${resource.name}`)} - ${selectedPoll.question}`;
                    }
                }

                acc.push({
                    ...column,
                    prop: column.id,
                    kind: column.kind,
                    schema: column.schema,
                    field: column.field,
                    label,
                    sortable: true
                });

                return acc;
            }, []);
            this.$emit('confirm', formattedColumnsForTable);
        }
    }
};
</script>
<style lang="scss">
@include block(pendo-resource-column-chooser) {
    @include element(footer) {
        display: flex;
        justify-content: flex-end;
    }
    @include element(modal) {
        .pendo-modal__content {
            overflow: visible;
        }
    }
}
</style>
