<template>
    <div class="pendo-guide-activity-table">
        <pendo-table
            ref="guideActivityTable"
            :data="activityData"
            :columns="columns"
            :csv-download="showCsvDownloadButton && downloadCsv"
            :default-sort="sortConfig"
            :scroll-config="{ rowHeight: null }"
            :tree-config="{ children: 's' }"
            :height="height"
            :borderless="borderless"
            @sort-change="onSortChange">
            <template #status>
                <pendo-status-overlay
                    :state="guideActivityAggStatus"
                    :options="loadingOptions" />
            </template>
            <template
                v-if="showTableHeader"
                #headerLeft>
                <div class="pendo-guide-activity-table__header">
                    <div class="pendo-guide-activity-table__title">
                        Guide Activity
                    </div>
                    <pendo-multiselect
                        class="pendo-guide-activity-table__type-filter"
                        :value="selectedActivityType"
                        :options="activityTypeOptions"
                        :max-menu-width="120"
                        :append-to-body="false"
                        @select="onActivityTypeSelect">
                        <template #trigger>
                            <pendo-chart-trigger />
                        </template>
                    </pendo-multiselect>
                    <pendo-multiselect
                        v-if="showActivityFilter"
                        class="pendo-guide-activity-table__type-filter"
                        :value="selectedActivityFilter"
                        :allow-empty="false"
                        :options="activityFilterOptions"
                        :max-menu-width="170"
                        :append-to-body="false"
                        @select="onActivityFilterSelect">
                        <template #trigger>
                            <pendo-chart-trigger />
                        </template>
                    </pendo-multiselect>
                </div>
            </template>
            <template
                v-if="showTableHeader"
                #headerActions>
                <pendo-icon-button
                    tooltip="Manage columns"
                    class="pendo-table__title-action pendo-table__title-action--columns"
                    icon="columns"
                    icon-size="16"
                    @click="manageColumns" />
            </template>
            <template #stepsSeen="{ row }">
                <pendo-guide-progress-cell
                    v-if="!row.stepIndex"
                    :row="row" />
                <template v-if="row.stepIndex">
                    <div
                        v-pendo-tooltip="{
                            content: overflowTooltipContent,
                            delay: { show: 300, hide: 0 },
                            arrow: true,
                            multiline: true,
                            boundariesElement: 'body'
                        }"
                        class="pendo-guide-activity-table__steps-seen"
                        @mouseenter="checkForOverflowingContent($event, row)"
                        @focus="checkForOverflowingContent($event, row)">
                        {{ getStepSeenText(row) }}
                        <pendo-app-display
                            v-if="row.stepApp"
                            :apps="row.stepApp" />
                    </div>
                </template>
            </template>
            <template #stepApp="{ row }">
                <pendo-app-display
                    v-if="row.stepApp"
                    :apps="row.stepApp" />
            </template>
        </pendo-table>
        <pendo-resource-column-chooser
            :visible="showManageColumnsModal"
            :columns="columns"
            :saving="savingColumnChooser"
            :features-list="featuresList"
            :guides-list="guidesList"
            :pages-list="pagesList"
            :is-loading-resources="isLoadingResources"
            :is-loading-metadata="isLoadingMetadata"
            :metadata-list="metadataList"
            @fetch-resources="fetchResources"
            @cancel="showManageColumnsModal = false"
            @close="showManageColumnsModal = false"
            @confirm="onConfirmColumnsChange" />
    </div>
</template>

<script>
import PendoMultiselect from '@/components/multiselect/pendo-multiselect.vue';
import PendoChartTrigger from '@/components/multiselect/trigger-types/pendo-chart-trigger.vue';
import PendoTable from '@/components/table/pendo-table.vue';
import PendoIconButton from '@/components/icon-button/pendo-icon-button.vue';
import PendoGuideProgressCell from '@/components/table/cell-types/pendo-guide-progress-cell.vue';
import PendoStatusOverlay from '@/composites/status-overlay/pendo-status-overlay.vue';
import PendoResourceColumnChooser from '@/composites/resource-column-chooser/pendo-resource-column-chooser.vue';
import PendoAppDisplay from '@/composites/app-display/pendo-app-display.vue';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';

export default {
    name: 'PendoGuideActivityTable',
    components: {
        PendoChartTrigger,
        PendoGuideProgressCell,
        PendoIconButton,
        PendoMultiselect,
        PendoTable,
        PendoStatusOverlay,
        PendoResourceColumnChooser,
        PendoAppDisplay
    },
    directives: {
        PendoTooltip
    },
    props: {
        /**
         * Table sort configuration object (see pendo-table)
         */
        sortConfig: {
            type: Object,
            default: () => ({
                prop: 'visitorId',
                order: 'none'
            })
        },
        /**
         * Table columns (see pendo-table)
         */
        columns: {
            type: Array,
            required: true
        },
        /**
         * Guide activity data rows
         */
        activityData: {
            type: Array,
            required: true
        },
        /**
         * Loading status for primary data aggregation (loading, rejected, empty)
         */
        guideActivityAggStatus: {
            type: String,
            required: true
        },
        /**
         * Optional configuration inputs for loading indicator: [props](https://github.com/pendo-io/components/blob/master/src/composites/status-overlay/pendo-status-overlay.vue#L65)
         */
        loadingOptions: {
            type: Object,
            default: () => {}
        },
        /**
         * Can by `byVisitor` or `byEvent`
         */
        selectedActivityType: {
            type: Object,
            default: () => ({
                id: 'byVisitor',
                label: 'By Visitor'
            })
        },
        /**
         * Can be `everyone` `seen` or `notSeen`
         */
        selectedActivityFilter: {
            type: Object,
            default: () => ({
                id: 'everyone',
                label: 'Everyone'
            })
        },
        /**
         * Control if activity filter is shown
         */
        showActivityFilter: {
            type: Boolean,
            default: false
        },
        /**
         * List of features
         */
        featuresList: {
            type: Array,
            default: () => []
        },
        /**
         * List of guides
         */
        guidesList: {
            type: Array,
            default: () => []
        },
        /**
         * List of pages
         */
        pagesList: {
            type: Array,
            default: () => []
        },
        /**
         * Column Chooser Modal saving status
         */
        savingColumnChooser: {
            type: Boolean,
            default: false
        },
        /**
         * Loading indicator for aggs that's dependent on the user's selection
         */
        isLoadingResources: {
            type: Boolean,
            default: false
        },
        /**
         * Loading indicator for metadata (available column options)
         */
        isLoadingMetadata: {
            type: Boolean,
            default: false
        },
        /**
         * List of metadata
         */
        metadataList: {
            type: Array,
            default: () => []
        },
        /**
         * Whether to show csv download button
         */
        showCsvDownloadButton: {
            type: Boolean,
            default: false
        },
        /** Whether to show the table header
         */
        showTableHeader: {
            type: Boolean,
            default: true
        },
        /** Table height in pixels */
        height: {
            type: Number,
            default: 600
        },
        /** Outer table border visibility */
        borderless: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            showManageColumnsModal: false,
            activityTypeOptions: [
                {
                    id: 'byVisitor',
                    label: 'By Visitor'
                },
                {
                    id: 'byEvent',
                    label: 'All Events'
                }
            ],
            activityFilterOptions: [
                {
                    id: 'everyone',
                    label: 'Everyone'
                },
                {
                    id: 'seen',
                    label: 'Has Seen Guide'
                },
                {
                    id: 'notSeen',
                    label: 'Has Not Seen Guide'
                }
            ],
            overflowTooltipContent: null
        };
    },
    watch: {
        savingColumnChooser (newVal, oldVal) {
            // Wait until the modal data is saved (user settings updated) before closing the modal
            if (!newVal && oldVal && this.showManageColumnsModal) {
                this.showManageColumnsModal = false;
            }
        }
    },
    methods: {
        onSortChange ({ prop, order }) {
            this.$emit('sortChange', { prop, order });
        },
        onActivityTypeSelect (type) {
            this.$emit('activityTypeSelect', type);
        },
        onActivityFilterSelect (filter) {
            this.$emit('activityFilterSelect', filter);
        },
        downloadCsv () {
            this.$emit('downloadCsv');
        },
        manageColumns () {
            this.$emit('manageColumns');
            this.showManageColumnsModal = true;
        },
        fetchResources (id) {
            this.$emit('fetchResources', id);
        },
        onConfirmColumnsChange (formattedColumnsForTable) {
            this.$emit('confirmColumnsChange', formattedColumnsForTable);
        },
        checkForOverflowingContent (event, row) {
            this.overflowTooltipContent = null;

            const { scrollWidth, clientWidth } = event.target;

            if (scrollWidth > clientWidth) {
                const tooltipText = this.getStepSeentext(row);
                this.overflowTooltipContent = tooltipText;
            }
        },
        getStepSeenText (row) {
            let stepSeentext = `Step ${row.stepIndex}`;
            if (row.appName) {
                stepSeentext += ' - ';
            }

            return stepSeentext;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-guide-activity-table) {
    display: contents;

    @include element(header) {
        display: grid;
        grid-gap: 16px;
        grid-auto-flow: column;
        align-items: center;
    }

    @include element(steps-seen) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        .pendo-app-display {
            display: inline;
        }
    }
}
</style>
