<template>
    <pendo-drawer
        :visible.sync="isVisible"
        :title="title"
        :placement="placement"
        size="fullscreen"
        :append-to="appendTo"
        :z-index="zIndex"
        @open="handleDrawerOpen"
        @opened="handleDrawerOpened">
        <template #body>
            <div
                v-if="layouts && isVisible && !isDrawerOpening"
                class="pendo-layout-chooser">
                <slot name="topSection" />
                <slot name="filters" />
                <slot name="beforeLayouts">
                    <pendo-card
                        v-if="showAppThemeCard()"
                        :title="appThemeCardTitle"
                        body-min-height="118px"
                        class="pendo-layout-chooser__app-theme-card">
                        <slot name="appChooser">
                            <div
                                v-if="apps"
                                class="pendo-layout-chooser__app-selector">
                                <pendo-multiselect
                                    :value="selectedApp"
                                    preselect-first
                                    full-width
                                    label-key="name"
                                    :allow-empty="false"
                                    :labels="{ top: 'Application' }"
                                    :options="apps"
                                    :disabled="loading || apps.length === 1"
                                    :loading="loading"
                                    @select="selectApp">
                                    <template #selectedLabel="{ option }">
                                        <pendo-app-display
                                            v-if="useAppDisplay"
                                            :apps="option" />
                                        <pendo-icon-option
                                            v-if="!useAppDisplay"
                                            :option="option" />
                                    </template>
                                    <template #option="{ option }">
                                        <pendo-app-display
                                            v-if="useAppDisplay"
                                            :apps="option" />
                                        <pendo-icon-option
                                            v-if="!useAppDisplay"
                                            :option="option" />
                                    </template>
                                </pendo-multiselect>
                            </div>
                        </slot>
                        <slot name="themeChooser">
                            <div
                                v-if="themes"
                                class="pendo-layout-chooser__theme-selector">
                                <pendo-multiselect
                                    :value="selectedTheme"
                                    label-key="name"
                                    full-width
                                    preselect-first
                                    :loading="loading"
                                    :allow-empty="false"
                                    :disabled="loading || themes.length === 1"
                                    :labels="{ top: 'Theme' }"
                                    :options="themes"
                                    @select="selectTheme" />
                            </div>
                        </slot>
                    </pendo-card>
                </slot>
                <slot name="layouts">
                    <pendo-layout-grid
                        v-if="defer(2)"
                        title="Layout"
                        v-bind="$props"
                        v-on="$listeners" />
                </slot>
                <slot name="afterLayouts" />
            </div>
        </template>
        <div
            v-if="!layouts && isVisible && !isDrawerOpening"
            class="pendo-layout-chooser pendo-layout-chooser--v2">
            <slot name="topSection" />
            <pendo-card
                v-if="apps"
                body-min-height="100px"
                :title="sectionTitles.layoutFilters">
                <div class="pendo-layout-chooser__layout-filters">
                    <div class="app-selector">
                        <pendo-multiselect
                            :value="selectedApp"
                            preselect-first
                            label-key="name"
                            placeholder="Select an Application"
                            :allow-empty="false"
                            :labels="{ top: 'Application' }"
                            :options="apps"
                            :disabled="loading || apps.length === 1"
                            :loading="loading"
                            @select="selectApp">
                            <template #selectedLabel="{ option }">
                                <pendo-app-display
                                    v-if="useAppDisplay"
                                    :apps="option" />
                                <pendo-icon-option
                                    v-if="!useAppDisplay"
                                    :option="option" />
                            </template>
                            <template #option="{ option }">
                                <pendo-app-display
                                    v-if="useAppDisplay"
                                    :apps="option" />
                                <pendo-icon-option
                                    v-if="!useAppDisplay"
                                    :option="option" />
                            </template>
                        </pendo-multiselect>
                    </div>
                    <div
                        v-if="guideCategories"
                        class="guide-category-selector">
                        <pendo-multiselect
                            :value="selectedGuideCategory"
                            label-key="name"
                            group-options-key="items"
                            group-label-key="name"
                            value-key="id"
                            min-menu-width="250"
                            placeholder="Select a Category"
                            :labels="{ top: 'Category' }"
                            :options="guideCategories"
                            :disabled="loading || guideCategories.length === 1"
                            :loading="loading"
                            @select="selectGuideCategory">
                            <template
                                v-if="emptyOption"
                                #header="{ select }">
                                <div class="pendo-layout-chooser__guide-category-sticky-header">
                                    <div
                                        :class="[
                                            'pendo-multiselect__option',
                                            'pendo-layout-chooser__guide-category-sticky-header--option',
                                            {
                                                'is-selected': selectedGuideCategory.name === emptyOption.name
                                            }
                                        ]"
                                        @click="select(emptyOption)"
                                        @keydown.enter="select(emptyOption)">
                                        {{ emptyOption.name }}
                                    </div>
                                </div>
                            </template>
                        </pendo-multiselect>
                    </div>
                </div>
            </pendo-card>
            <pendo-card
                v-if="showLayoutTypeChooser"
                :title="sectionTitles.layoutTypeChooser">
                <pendo-layout-type-chooser
                    class="pendo-layout-chooser__layout-type-chooser"
                    :layout-type="selectedLayoutType"
                    :disabled="loading || disableLayoutTypeChooser"
                    @select-layout-type="selectLayoutType" />
            </pendo-card>
            <pendo-layout-grid
                :title="sectionTitles.layoutChooser"
                :layouts="
                    [
                        defer(2) &&
                            organizationLayouts &&
                            organizationLayouts.length && {
                                name: 'org-layouts',
                                title: organizationLayoutsTitle,
                                children: organizationLayouts
                            },
                        defer(3) &&
                            customLayouts && {
                                name: 'custom-layouts',
                                title: customLayoutsTitle,
                                children: customLayouts
                            },
                        defer(4) &&
                            defaultLayouts &&
                            defaultLayouts.length && {
                                name: 'default-layouts',
                                title: defaultLayoutsTitle,
                                children: defaultLayouts
                            }
                    ].filter(Boolean)
                "
                v-bind="$props"
                v-on="$listeners">
                <template #beforeOrganizationLayouts>
                    <slot name="beforeOrganizationLayouts" />
                </template>
                <template #beforeCustomLayouts>
                    <slot name="beforeCustomLayouts" />
                </template>
                <template #beforeLayouts>
                    <div
                        v-if="themes"
                        class="pendo-layout-chooser__theme-selector">
                        <pendo-multiselect
                            :value="selectedTheme"
                            label-key="name"
                            :min-trigger-width="162"
                            preselect-first
                            :loading="loading"
                            :allow-empty="false"
                            :disabled="loading || themes.length === 1"
                            :labels="{ left: 'Theme' }"
                            :options="themes"
                            @select="selectTheme" />
                    </div>
                </template>
                <template #empty>
                    <slot name="empty" />
                </template>
            </pendo-layout-grid>
        </div>
    </pendo-drawer>
</template>

<script>
import PendoCard from '@/components/card/pendo-card';
import PendoDrawer from '@/components/drawer/pendo-drawer';
import PendoAppDisplay from '@/composites/app-display/pendo-app-display';
import PendoIconOption from '@/components/multiselect/option-types/pendo-icon-option';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoLayoutGrid from '@/composites/layout-chooser/pendo-layout-grid';
import PendoLayoutTypeChooser from '@/composites/layout-chooser/pendo-layout-type-chooser';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';

import Defer from '@/mixins/defer';

export default {
    name: 'PendoLayoutChooser',
    components: {
        PendoAppDisplay,
        PendoCard,
        PendoDrawer,
        PendoIconOption,
        PendoLayoutGrid,
        PendoLayoutTypeChooser,
        PendoMultiselect
    },
    directives: {
        PendoTooltip
    },
    mixins: [Defer()],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        layouts: {
            type: Array,
            default: null
        },
        selectedLayoutType: {
            type: String,
            default: 'overlay',
            validator: (type) => ['overlay', 'embedded'].includes(type)
        },
        disableLayoutTypeChooser: {
            type: Boolean,
            default: false
        },
        showLayoutTypeChooser: {
            type: Boolean,
            default: false
        },
        defaultLayouts: {
            type: Array,
            default: null
        },
        defaultLayoutsTitle: {
            type: String,
            default: 'Default'
        },
        /**
         * Subscription level layouts to render in the "Saved Layouts" section of the component
         */
        customLayouts: {
            type: Array,
            default: null
        },
        customLayoutsTitle: {
            type: String,
            default: 'Saved'
        },
        organizationLayouts: {
            type: Array,
            default: null
        },
        organizationLayoutsTitle: {
            type: String,
            default: 'Organization'
        },
        title: {
            type: String,
            default: 'Create Guide'
        },
        /* eslint-disable vue/no-unused-properties */
        disabled: {
            type: Boolean,
            default: false
        },
        allowActions: {
            type: Object,
            default: () => ({})
        },
        filters: {
            type: [Object, Array],
            default: () => [
                {
                    prop: ['title'],
                    value: ''
                }
            ]
        },
        /* eslint-enable vue/no-unused-properties */
        loading: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String,
            default: 'bottom'
        },
        zIndex: {
            type: Number,
            default: 502
        },
        apps: {
            type: Array,
            default: null
        },
        useAppDisplay: {
            type: Boolean,
            default: false
        },
        selectedApp: {
            type: Object,
            default: null
        },
        themes: {
            type: Array,
            default: null
        },
        selectedTheme: {
            type: Object,
            default: null
        },
        guideCategories: {
            type: Array,
            default: null
        },
        selectedGuideCategory: {
            type: Object,
            default: null
        },
        emptyOption: {
            type: Object,
            default: null
        },
        /**
         * Valid selector to append drawer portal to. `false` leaves drawer in tree
         */
        appendTo: {
            type: [String, Element, Boolean],
            default: 'body'
        }
    },
    emits: ['select-layout-type'],
    data () {
        return {
            isDrawerOpening: false
        };
    },
    computed: {
        isVisible: {
            get () {
                // backwards compatibility until existing layout choosers in appengine
                // and designer can be updated. to be removed in next release
                if (this.$options.propsData.visible == null) {
                    return true;
                }

                return this.visible;
            },
            set (val) {
                this.$emit('update:visible', val);

                if (!val) {
                    /**
                     * Emitted when user closes the drawer overlay
                     *
                     * @event close
                     * @property {Event} $event - DOM Event
                     */
                    this.$emit('close');
                    /**
                     * @deprecated Use `@close` event instead.
                     * @since 2.5.0
                     * @property {Event} $event - DOM Event
                     */
                    this.$emit('onClose');
                }
            }
        },
        sectionTitles () {
            const layoutFilterSectionTitle = this.guideCategories ? 'Select app and category' : 'Select app';
            const sections = [
                this.apps && ['layoutFilters', layoutFilterSectionTitle],
                this.showLayoutTypeChooser && ['layoutTypeChooser', 'Choose how this guide appears on the page'],
                ['layoutChooser', 'Select layout']
            ].filter(Boolean);

            return sections.reduce((acc, [key, value], index) => {
                acc[key] = sections.length > 1 ? `${index + 1}. ${value}` : value;

                return acc;
            }, {});
        },
        appThemeCardTitle () {
            const title = [];
            if (this.$slots.appChooser || this.apps) {
                title.push('Application');
            }

            if (this.$slots.themeChooser || this.themes) {
                title.push('Theme');
            }

            return title.join(' & ');
        }
    },
    methods: {
        showAppThemeCard () {
            return (
                this.apps ||
                this.themes ||
                this.$slots.appChooser ||
                this.$scopedSlots.appChooser ||
                this.$slots.themeChooser ||
                this.$scopedSlots.themeChooser
            );
        },
        handleDrawerOpen () {
            this.isDrawerOpening = true;
        },
        handleDrawerOpened () {
            this.isDrawerOpening = false;
        },
        selectApp (app) {
            /**
             * Emitted when user selects a theme
             *
             * @event select-app
             * @property {Event} $event - DOM Event
             */
            this.$emit('select-app', app);
        },
        selectTheme (theme) {
            /**
             * Emitted when user selects a theme
             *
             * @event select-app
             * @property {Event} $event - DOM Event
             */
            this.$emit('select-theme', theme);
        },
        selectGuideCategory (category) {
            /**
             * Emitted when user selects a guide category
             *
             * @event select-guide-category
             * @property {Event} $event - DOM Event
             */
            this.$emit('select-guide-category', category);
        },
        selectLayoutType (type) {
            /**
             * Emitted when user selects a guide layout type
             *
             * @event select-layout-type
             */
            this.$emit('select-layout-type', type);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-layout-chooser) {
    padding: 0 56px;

    > .pendo-card {
        min-width: 310px;

        &:not(:last-child) {
            margin-bottom: 48px;
        }
    }

    @include element(guide-category-sticky-header) {
        padding: 8px 0;
        border-bottom: 1px solid $color-gray-30;

        @include modifier(option) {
            line-height: 26px;

            &:hover:not(.is-selected) {
                background-color: $color-gray-20;
            }
        }
    }

    @include modifier(v2) {
        padding: 24px;
        grid-area: body;
        overflow-y: auto;
        display: grid;
        grid-gap: 24px;
        grid-auto-rows: max-content;

        @include element(layout-filters) {
            display: flex;
            gap: 24px;
            padding: 0 24px;
        }

        @include element(layout-type-chooser) {
            padding: 16px 24px;
        }

        > .pendo-card {
            min-width: 310px;

            &:last-child {
                margin-bottom: 24px;
            }

            &:not(:last-child) {
                margin-bottom: 0;
            }
        }

        .pendo-chooser-grid__items {
            justify-content: start;
        }
    }

    @include element(app-theme-card) {
        .pendo-card__body {
            padding: 24px;
            display: grid;
            grid-auto-columns: 246px;
            grid-auto-flow: column;
            grid-gap: 32px;
        }
    }
}
</style>
